@import '~antd/dist/antd.less';


html {
    overflow-y: scroll;
}

.App {
    padding-left: calc(100vw - 100%);
    font-family: "Barlow";

    .ant-list-item:hover {
        box-shadow: 0 0 2px #000;
        border-radius: 12px;
    }
    
    .ant-btn:hover {
        border-color: #232323;
        color: #232323;
    }

    .ant-pagination-item {
        a {
            color: #76BEBE
        }
        background-color: #EAF2F0;
        border-color: #EAF2F0;
        border-radius: 4px;
    }

    .ant-pagination-item-active {
        a {
            color: #232323;
        }
    }
}
@border-radius-base: 8px;